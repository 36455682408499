import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainBacked.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

import skillfulD from "../../assets/partners/skillfulD.webp";
import skillfulL from "../../assets/partners/skillfulL.webp";
import stepD from "../../assets/partners/stepD.webp";
import stepL from "../../assets/partners/stepL.webp";
import wehodlD from "../../assets/partners/wehodlD.webp";
import wehodlL from "../../assets/partners/wehodlL.webp";
import threedotsD from "../../assets/partners/3dotsD.webp";
import threedotsL from "../../assets/partners/3dotsL.webp";
import triremeD from "../../assets/partners/TriremeD.webp";
import triremeL from "../../assets/partners/TriremeL.webp";
import grtinovaD from "../../assets/partners/gritnovaD.webp";
import grtinovaL from "../../assets/partners/gritnovaL.webp";
import badgeD from "../../assets/partners/thebadgeD.webp";
import badgeL from "../../assets/partners/thebadgeL.webp";
import corum8D from "../../assets/partners/corum8D.webp";
import corum8L from "../../assets/partners/corum8L.webp";
import nfmediaD from "../../assets/partners/nfmediaD.webp";
import nfmediaL from "../../assets/partners/nfmediaL.webp";
import finnovantD from "../../assets/partners/finnovantD.webp";
import finnovantL from "../../assets/partners/finnovantL.webp";
import redbellyD from "../../assets/partners/redbellyD.webp";
import redbellyL from "../../assets/partners/redbellyL.webp";
import cookieD from "../../assets/partners/cookie3D.webp";
import cookieL from "../../assets/partners/cookie3L.webp";
import avaD from "../../assets/partners/avalancheD.webp";
import avaL from "../../assets/partners/avalancheL.webp";
import bnbD from "../../assets/partners/bnbD.webp";
import bnbL from "../../assets/partners/bnbL.webp";
import cultureD from "../../assets/partners/clutureD.webp";
import cultureL from "../../assets/partners/clutureL.webp";

import chainGptD from "../../assets/partners/chaingpt.svg";
import chainGptL from "../../assets/partners/chaingptL.webp";
import arenaD from "../../assets/partners/arenaGamesD.png";
import arenaL from "../../assets/partners/arenaGamesL.png";
import gainsD from "../../assets/partners/gains.svg";
import gainsL from "../../assets/partners/gains.svg";
import emoneyD from "../../assets/partners/emoneyD.webp";
import emoneyL from "../../assets/partners/emoneyL.webp";
import artheraD from "../../assets/partners/artheraD.png";
import artheraL from "../../assets/partners/artheraL.png";
import helicodeD from "../../assets/partners/helicodeD.png";
import helicodeL from "../../assets/partners/helicodeL.png";
import scholesD from "../../assets/partners/scholes.png";
import scholesL from "../../assets/partners/scholesL.png";
import kimaD from "../../assets/partners/kimaD.png";
import kimaL from "../../assets/partners/kimaL.png";
import coinD from "../../assets/partners/coinpaprikaD.png";
import coinL from "../../assets/partners/coinPaprikaL.png";
import whiteBridgeD from "../../assets/partners/whitebridgeD.png";
import whiteBridgeL from "../../assets/partners/whitebridgeL.png";
import chainawareD from "../../assets/partners/chainawareD.png";
import chainawareL from "../../assets/partners/chainawareL.png";
import forceFiD from "../../assets/partners/forcefiD.png";
import forceFiL from "../../assets/partners/forcefiL.png";
import klinkD from "../../assets/partners/klinkD.png";
import klinkL from "../../assets/partners/klinkL.png";

import veridaD from "../../assets/partners/veridaD.png";
import veridaL from "../../assets/partners/veridaL.png";

import amplifiD from "../../assets/partners/amplifiD.png";
import amplifiL from "../../assets/partners/amplifiL.png";
import GhostyCashD from "../../assets/partners/GhostyCashD.png";
import GhostyCashL from "../../assets/partners/GhostyCashL.png";
import LaunchPunksD from "../../assets/partners/LaunchPunks.png";
import StarLightL from "../../assets/partners/StarLightL.png";
import StarLightD from "../../assets/partners/StarLightD.png";
import OnePredictL from "../../assets/partners/OnePredictL.png";
import OnePredictD from "../../assets/partners/OnePredictD.png";
import EthereumL from "../../assets/partners/EthereumL.png";
import EthereumD from "../../assets/partners/ErhereumD.png";
import DmanyD from "../../assets/partners/DmanyD.png";
import DmanyL from "../../assets/partners/DmanyL.png";
import RogueD from "../../assets/partners/RogueD.png";
import RogueL from "../../assets/partners/RogueL.png";
import trailblazeD from "../../assets/partners/TrailblazeL.png";
import trailblazeL from "../../assets/partners/Trailblaze.png";
import InternetComputerD from "../../assets/partners/InternetComputerD.png";
import InternetComputerL from "../../assets/partners/InternetComputerL.png";
import SuperVerseD from "../../assets/partners/SuperVerseD.png";
import SuperVerseL from "../../assets/partners/SuperVerseL.png";
import SoccernD from "../../assets/partners/SoccerD.png";
import SoccernL from "../../assets/partners/SoccerL.png";
import BioFiD from "../../assets/partners/BioFiD.png";
import BioFiL from "../../assets/partners/BioFiL.png";
import ElympicsD from "../../assets/partners/ElympicsD.png";
import ElympicsL from "../../assets/partners/ElympicsL.png";
import zkagiD from "../../assets/partners/ZkAGID.png";
import zkagiL from "../../assets/partners/ZakAGIL.png";
import UltradeD from "../../assets/partners/UltradeD.svg";
import UltradeL from "../../assets/partners/UltradeL.svg";
import GalxeD from "../../assets/partners/Galxe-dark.svg";
import GalxeL from "../../assets/partners/Galxe-light.svg";
import TonD from "../../assets/partners/TonD.png";
import TonL from "../../assets/partners/TonL.png";
import IBCD from "../../assets/partners/IBCD.png";

function Backed() {
  const { theme } = useTheme();

  const backedData = [
    {
      name: "Gritnova",
      logoD: grtinovaD,
      logoL: grtinovaL,
      link: "https://gritnova.com/",
    },
    {
      name: "Trireme Trading",
      logoD: triremeD,
      logoL: triremeL,
      link: "https://www.triremetrading.com/",
    },
    {
      name: "WeeHODL Capital",
      logoD: wehodlD,
      logoL: wehodlL,
      link: "https://weehodl.com/",
    },
    {
      name: "3dots",
      logoD: threedotsD,
      logoL: threedotsL,
      link: "https://3dots.ooo/",
    },
    {
      name: "Skillful Ai",
      logoD: skillfulD,
      logoL: skillfulL,
      link: "https://skillfulai.io/",
    },
    {
      name: "The Badge",
      logoD: badgeD,
      logoL: badgeL,
      link: "https://www.thebadge.xyz/",
    },
    {
      name: "Corum8",
      logoD: corum8D,
      logoL: corum8L,
      link: "https://corum8.com/",
    },
    {
      name: "Step in web3",
      logoD: stepD,
      logoL: stepL,
      link: "https://stepinweb3.com",
    },
    {
      name: "NF Media",
      logoD: nfmediaD,
      logoL: nfmediaL,
      link: "https://nfmedia.org/",
    },
    {
      name: "Finnovant",
      logoD: finnovantD,
      logoL: finnovantL,
      link: "https://www.finnovant.com/",
    },
    {
      name: "RedBelly",
      logoD: redbellyD,
      logoL: redbellyL,
      link: "https://www.redbelly.network/",
    },
    {
      name: "Cookie3",
      logoD: cookieD,
      logoL: cookieL,
      link: "https://www.cookie3.com/",
    },
    {
      name: "Avanalnce",
      logoD: avaD,
      logoL: avaL,
      link: "https://www.avax.network/",
    },
    {
      name: "Culture",
      logoD: cultureD,
      logoL: cultureL,
      link: "https://thecultureclub.us/",
    },
    {
      name: "BNB",
      logoD: bnbD,
      logoL: bnbL,
      link: "https://www.bnbchain.org/en",
    },
    {
      name: "ChainGPT",
      logoD: chainGptD,
      logoL: chainGptL,
      link: "https://www.chaingpt.org/",
    },
    {
      name: "Arena Games",
      logoD: arenaD,
      logoL: arenaL,
      link: "https://arenavs.com/",
    },
    {
      name: "Gains Associates",
      logoD: gainsD,
      logoL: gainsL,
      link: "https://www.gains-associates.com/",
    },
    {
      name: "E-Money Network",
      logoD: emoneyD,
      logoL: emoneyL,
      link: "https://emoney.io/",
    },

    {
      name: "Arthera Chain",
      logoD: artheraD,
      logoL: artheraL,
      link: "https://www.arthera.net/",
    },
    {
      name: "Helicode",
      logoD: helicodeD,
      logoL: helicodeL,
      link: "https://helicode.xyz/",
    },
    {
      name: "Scholes",
      logoD: scholesD,
      logoL: scholesL,
      link: "https://www.scholes.xyz/",
    },
    {
      name: "Kima",
      logoD: kimaD,
      logoL: kimaL,
      link: "https://www.kima.network/",
    },
    {
      name: "CoinPaprkia",
      logoD: coinD,
      logoL: coinL,
      link: "https://coinpaprika.com/",
    },
    {
      name: "WhiteBridge",
      logoD: whiteBridgeD,
      logoL: whiteBridgeL,
      link: "https://whitebridge.network/",
    },
    {
      name: "ChainAware.ai",
      logoD: chainawareD,
      logoL: chainawareL,
      link: "https://chainaware.ai/",
    },
    {
      name: "ForceFi",
      logoD: forceFiD,
      logoL: forceFiL,
      link: "https://www.forcefi.io/",
    },
    {
      name: "Klink Finance",
      logoD: klinkD,
      logoL: klinkL,
      link: "https://www.klinkfinance.com/",
    },
    {
      name: "Verida",
      logoD: veridaD,
      logoL: veridaL,
      link: "https://www.verida.network/",
    },

    {
      name: "Amplifi",
      logoD: amplifiD,
      logoL: amplifiL,
      link: "https://www.amplifi.fi/",
    },
    {
      name: "GhostyCash",
      logoD: GhostyCashD,
      logoL: GhostyCashL,
      link: "https://www.ghosty.cash/",
    },
    {
      name: "LaunchPunks",
      logoD: LaunchPunksD,
      logoL: LaunchPunksD,
      link: "https://www.launchpunks.com/",
    },
    {
      name: "StarLight",
      logoD: StarLightD,
      logoL: StarLightL,
      link: "https://starlight.foundation/",
    },
    {
      name: "OnePredict",
      logoD: OnePredictL,
      logoL: OnePredictD,
      link: "https://t.me/onepredict_bot",
    },
    {
      name: "Ethereum",
      logoD: EthereumD,
      logoL: EthereumL,
      link: "https://ethereum.org/en/",
    },
    {
      name: "Dmany",
      logoD: DmanyL,
      logoL: DmanyD,
      link: "https://dmany.io/",
    },
    {
      name: "Rogue",
      logoD: RogueD,
      logoL: RogueL,
      link: "https://roguechain.io/",
    },
    {
      name: "Trailblaze",
      logoD: trailblazeL,
      logoL: trailblazeD,
      link: "https://www.trailblaze.xyz/",
    },
    {
      name: "ICP",
      logoD: InternetComputerD,
      logoL: InternetComputerL,
      link: "https://internetcomputer.org/",
    },
    {
      name: "SuperVerse",
      logoD: SuperVerseD,
      logoL: SuperVerseL,
      link: "https://superverse.co/",
    },
    {
      name: "Soccern",
      logoD: SoccernD,
      logoL: SoccernL,
      link: "https://www.soccern.io/",
    },
    {
      name: "BioFi",
      logoD: BioFiD,
      logoL: BioFiL,
      link: "https://biometricfinancial.org/",
    },
    {
      name: "Elympics",
      logoD: ElympicsD,
      logoL: ElympicsL,
      link: "https://www.elympics.ai/",
    },
    {
      name: "Zkagi",
      logoD: zkagiD,
      logoL: zkagiL,
      link: "https://www.zkagi.ai/",
    },
    {
      name: "Ultrade",
      logoD: UltradeL,
      logoL: UltradeD,
      link: "https://ultrade.org/",
    },
    {
      name: "Galxe",
      logoD: GalxeD,
      logoL: GalxeL,
      link: "https://www.galxe.com/",
    },
    {
      name: "Ton",
      logoD: TonD,
      logoL: TonL,
      link: "https://ton.org/",
    },
    {
      name: "IBC",
      logoD: IBCD,
      logoL: IBCD,
      link: "https://ton.org/",
    },
  ];
  return (
    <div className={`backed ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="backed-container">
        <div className="backed-title">Backed By</div>
        <div className="backed-icons">
          {backedData.map((item, index) => (
            <div className="backed-icon" key={index}>
              <a href={item.link} target="_blank" rel="noreferrer">
                <img
                  src={theme === "light" ? item.logoL : item.logoD}
                  alt={`Backed by ${item.name}`}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="backed-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default Backed;
