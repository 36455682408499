import React from "react";
import { useTheme } from "../../ThemeProvider";
import "./mainStatement.css";
import logo from "../../assets/svg/logo-inner-colored.svg";

function Statement() {
  const { theme } = useTheme();

  return (
    <div className={`statement ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="statement-container">
        <div className="statement-title">
          A New Era Powered By The Internet Of Value
        </div>
        <div className="statement-para">
          3VO is a web3 ecosystem that bridges finance to social, We empower
          business and personal brands with tools to build, tokenize and
          transact directly with their communities. <br />
          <br />
          By merging the principles of Web3 and Social Media, 3vo introduces a
          new paradigm that changes the way we perceive and exchange digital
          value. ​
        </div>
      </div>
      <div className="statement-background">
        <img src={logo} alt="3vo-superapp"></img>
      </div>
    </div>
  );
}

export default Statement;
