import React from "react";
import { useTheme } from "../../ThemeProvider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./mainCards.css";

import card1 from "../../assets/gallery/card1.webp";
import card2 from "../../assets/gallery/card2.webp";
import card3 from "../../assets/gallery/card3.webp";
import card4 from "../../assets/gallery/card4.webp";
import card5 from "../../assets/gallery/card5.webp";
import card6 from "../../assets/gallery/card6.webp";

function Cards() {
  const { theme } = useTheme();

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 5000,
    cssEase: "linear",
    slidesToShow: 5,
    slidesToScroll: 1,
    swipe: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cardData = [
    { type: "image", content: card1 },
    {
      type: "text1",
      content: card2,
      title: "+40.0K",
      description: "Community Members",
    },
    { type: "image", content: card3 },
    {
      type: "text2",
      content: card4,
      title: "40+",
      description: "Industry Partners",
    },
    { type: "image", content: card5 },
    {
      type: "text1",
      content: card6,
      title: "$220K",
      description: "Committed ",
    },
  ];

  return (
    <div className={`cards ${theme === "light" ? "" : "dark-theme"}`}>
      <div className="cards-container">
        <Slider {...settings}>
          {cardData.map((data, index) => (
            <div key={index}>
              {data.type === "image" ? (
                <div className="image-card">
                  <img src={data.content} alt={data.description} />
                </div>
              ) : data.type === "text1" ? (
                <div className="text-card">
                  <img src={data.content} alt={data.description} />
                  <div className="text-card-text">
                    <div className="text-card-title">{data.title}</div>
                    <div className="text-card-desc">{data.description}</div>
                  </div>
                </div>
              ) : (
                <div className="text-card">
                  <div className="text-card-text">
                    <div className="text-card-title">{data.title}</div>
                    <div className="text-card-desc">{data.description}</div>
                  </div>
                  <img src={data.content} alt={data.description} />
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Cards;
