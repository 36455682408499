import React, { useState } from "react";
import "./AdBar.css";

const AdBar = () => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <div>
      {visible && (
        <div className="ad-bar">
          <span className="ad-text">
            <a href="https://t.me/mysteryBox3voBot">
              Find up to $100,000 in 3VO Mystery Box Campaign
            </a>
          </span>
          <button className="close-btn" onClick={handleClose}>
            ✖
          </button>
        </div>
      )}

      <div
        className="header-container"
        style={{ marginTop: visible ? "0px" : "-12px" }}
      ></div>
    </div>
  );
};

export default AdBar;
